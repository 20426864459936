import React, { useState } from "react";
import ReactDOM from 'react-dom';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { ethers } from "ethers";
import Head from './Head';
import $ from "jquery"
import abi from './abi.json';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { Line, Circle } from 'rc-progress';
const Web3 = require('web3');
const Web3EthAbi = require('web3-eth-abi');
const provider = new Web3.providers.HttpProvider("https://white-convincing-sponge.base-mainnet.discover.quiknode.pro/a069a80cc25d32f6bdd0be7baa24bc4dd3a85347/");
const web3 = new Web3(provider);
const web4 = new Web3(window.ethereum);
const deployedContractAddress = '0xd39bd1c7a994edc928339f130e4bca46ef586f68';
const contract = new web3.eth.Contract(abi, deployedContractAddress);
const contract2 = new web4.eth.Contract(abi, deployedContractAddress);

/* global BigInt */

export default class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      metamask: true, //should be false
      walletconnect: false,
      connected: false,
      tokenAbi: undefined,
      tokenAddress: '',
      tokenBalanceInOwnerWallet: undefined,
      tokenBalanceInOwnerWalletInEth: undefined,
      tokenTotalSupply: undefined,
      tokenTotalSupplyInEth: undefined,
      tokenBasedlockSupply: undefined,
      tokenBasedlockSupplyInEth: undefined,
      tokenUnlockTime: undefined,
      tokenUnlockTimeInUtc: undefined,
      currentTime: undefined,
      timeToUnlock: undefined,
      timeToUnlockInDays: undefined,
      lockPercent: undefined,
      statusImg: undefined,
      token0Address: undefined,
      token1Address: undefined,
      token0Abi: undefined,
      token1Abi: undefined,
      token0Symbol: undefined,
      token1Symbol: undefined,
      tokenAdmin: false,
      lockFee: 10000000000000000,
      lockFeeInEth: '0.01',
      tokenAmountToLock: '',
      tokenOwner: undefined,
      lockLengthInput: '',
      connectionControlNumber: 0,
      connectButtonText: 'CONNECT WALLET',
      account: "no connected wallet"
    };
    this.checkUrlForTokenAddress = this.checkUrlForTokenAddress.bind(this);
    this.metamaskInstalled = this.metamaskInstalled.bind(this);
    this.connectMetamask = this.connectMetamask.bind(this);
    this.silentConnectMetamask = this.silentConnectMetamask.bind(this);
    this.walletconnectInstalled = this.walletconnectInstalled.bind(this);
    this.connectionEstablished = this.connectionEstablished.bind(this);
    this.getTokenContractAbi = this.getTokenContractAbi.bind(this);
    this.getPairDetails = this.getPairDetails.bind(this);
    this.getToken0Address = this.getToken0Address.bind(this);
    this.getToken1Address = this.getToken1Address.bind(this);
    this.getToken0Symbol = this.getToken0Symbol.bind(this);
    this.getToken1Symbol = this.getToken1Symbol.bind(this);
    this.getTokenTotalSupply = this.getTokenTotalSupply.bind(this);
    this.setLockLiquidityView = this.setLockLiquidityView.bind(this);
    this.setCheckLiquidityView = this.setCheckLiquidityView.bind(this);
    this.setStartViewFromCheckLiquidityView = this.setStartViewFromCheckLiquidityView.bind(this);
    this.handlePairAddressChange = this.handlePairAddressChange.bind(this);
    this.getLockData = this.getLockData.bind(this);
    this.getAmountInContract = this.getAmountInContract.bind(this);
    this.getAmountInContractPercentage = this.getAmountInContractPercentage.bind(this);
    this.getTokenUnlockTime = this.getTokenUnlockTime.bind(this);
    this.getCurrentTime = this.getCurrentTime.bind(this);
    this.getTimeToUnlock = this.getTimeToUnlock.bind(this);
    this.getCheckMark = this.getCheckMark.bind(this);
    this.getLockFee = this.getLockFee.bind(this);
    this.checkConnectionAfterConnect = this.checkConnectionAfterConnect.bind(this);
    this.startLockData = this.startLockData.bind(this);
    this.getTokenBalanceInOwnerWallet = this.getTokenBalanceInOwnerWallet.bind(this);
    this.scrollDown = this.scrollDown.bind(this);
    this.getTokenOwnerPercentage = this.getTokenOwnerPercentage.bind(this);
    this.getTokenPercentageOnTheFly = this.getTokenPercentageOnTheFly.bind(this);
    this.setTokenPercentageTo95 = this.setTokenPercentageTo95.bind(this);
    this.setTokenPercentageToMax = this.setTokenPercentageToMax.bind(this);
    this.notLpGiven = this.notLpGiven.bind(this);
    this.handleTokenAmountToLockChange = this.handleTokenAmountToLockChange.bind(this);
    this.getTokenOwner = this.getTokenOwner.bind(this);
    this.handleLockLengthChange = this.handleLockLengthChange.bind(this);
    this.startTheLock = this.startTheLock.bind(this);
    this.visitCertificate = this.visitCertificate.bind(this);
    this.extendTheLock = this.extendTheLock.bind(this);
    this.extendTheLockTime = this.extendTheLockTime.bind(this);
    this.approveLpTokenStartLock = this.approveLpTokenStartLock.bind(this);
    this.approveLpTokenExtendLock = this.approveLpTokenExtendLock.bind(this);
    this.approveLpTokenWithdrawTokens = this.approveLpTokenWithdrawTokens.bind(this);
    this.withdrawTokens = this.withdrawTokens.bind(this);
    this.payFee = this.payFee.bind(this);
  }
  checkUrlForTokenAddress () {
    let searchParams = new URLSearchParams(window.location.search);
    if (searchParams.has('token')) {
      let tmpTokenAddress = searchParams.get('token');
      this.setState({ tokenAddress: tmpTokenAddress }, () => {
        this.setCheckLiquidityView ();
        setTimeout(() => {
          this.getTokenContractAbi(tmpTokenAddress, 10);
        }, 300);
      });
    }
  }
  metamaskInstalled() {
    if(window.ethereum) {
      let network = web3.eth.net.getNetworkType();
      setTimeout(() => { console.log(network); }, 3000);
      let accounts = window.ethereum.selectedAddress;
      setTimeout(() => {
        console.log(accounts);
        if (accounts === null) {
          //this.showNotLoggedInToMetamaskMessage();
        } else {
          //this.showMetamaskLoginMessage();
        }
      }, 3000);
    }

    //this.setState({ account: accounts[0] }, () => {});
  }
  connectMetamask() {
    if(window.ethereum) {
      let accounts = window.ethereum.request({ method: 'eth_accounts' });
      let chainId = window.ethereum.request({ method: 'eth_chainId'});
      console.log ("chainID: ", chainId);
      //let wallet = accounts[0];
      let wallet = window.ethereum.selectedAddress;
      window.ethereum.enable();
      this.setState({ account: wallet }, () => {
        setTimeout(() => { this.metamaskInstalled(); this.checkConnectionAfterConnect(); }, 3000);
      });
      if (wallet != '0x0000000000000000000000000000000000000000' && wallet != '' && wallet != undefined && wallet != null) {
  	    window.ethereum.request({
            method: "wallet_requestPermissions",
            params: [
              {
                eth_accounts: {}
              }
            ]
       	});
      }
    } else {
      alert("Please install MetaMask");
    }
  }
  silentConnectMetamask() {
    if (this.state.tokenAdmin === true) {
      if(window.ethereum) {
        let wallet = window.ethereum.selectedAddress;
        this.setState({ account: wallet }, () => {});
        let tmp;
        let whenNotConnected = document.getElementById("whenNotConnected");
        let connectionStatus = document.getElementById("connectionStatus");
        let whenConnected = document.getElementById("whenConnected");
        if (wallet != '0x0000000000000000000000000000000000000000' && wallet != '' && wallet != undefined && wallet != null) {
          tmp = 'CHANGE WALLET';
          whenNotConnected.style.display = "none";
          setTimeout(() => {
            connectionStatus.classList.add("fadeIn");
            connectionStatus.style.display = "block";
          }, 100);
          this.getLockFee ();
          setTimeout(() => {
            whenConnected.classList.add("fadeIn");
            whenConnected.style.display = "block";
          }, 200);
        } else {
          tmp = 'CONNECT WALLET';
          connectionStatus.style.display = "none";
          whenConnected.style.display = "none";
          setTimeout(() => {
            whenNotConnected.classList.add("fadeIn");
            whenNotConnected.style.display = "block";
          }, 100);
        }
        this.setState({ connectButtonText: tmp }, () => {

        });
      } else {
        console.log ("window.ethereum not ready");
      }
    }
  }
  walletconnectInstalled() {

  }
  connectionEstablished() {

  }
  getTokenContractAbi (token_address, switcher) {
    fetch('https://api.basedlock.com/base-contract-getabi.php?token=' + token_address)
      .then(response => response.json())
      .then(output => {
	let data = output;
	if (switcher == "10") {
	  if (data['message'] == "OK") {
	    let parsedAbi = JSON.parse(data['result']);
	    this.setState({tokenAbi: parsedAbi, tokenAddress: token_address});
	    console.log ("Main Token Address: ", token_address);
	    console.log ("Main Token Contract ABI has been loaded successfully.");
	    this.getPairDetails ();
      let pairFound = document.getElementById("tokenPairFound");
      pairFound.classList.add("fadeIn");
      pairFound.style.display = "block";
      if (this.state.tokenAdmin === true) {
        let statusImgHolder = document.getElementById("statusImgHolder");
        statusImgHolder.style.display = "none";
      }
	  } else {
	    this.setState({tokenAbi: '', tokenAddress: ''});
	    console.log ("Main Token Contract ABI can not be loaded.");
      let pairFound = document.getElementById("tokenPairFound");
      pairFound.style.display = "none";
      let pairNotFound = document.getElementById("tokenPairNotFound");
      pairNotFound.classList.add("fadeIn");
      pairNotFound.style.display = "block";
	  }
	}
	if (switcher == "0") {
	  if (data['message'] == "OK") {
	    let parsed0Abi = JSON.parse(data['result']);
		  console.log (parsed0Abi);
	    this.setState({token0Abi: parsed0Abi, token0Address: token_address});
	    console.log ("Token0 Contract ABI has been loaded successfully.");
	    this.getToken0Symbol ();
	  } else {
	    this.setState({token0Abi: '', token0Address: '', token0Symbol: ''});
	    console.log ("Token0 Contract ABI can not be loaded.");
      let pairFound = document.getElementById("tokenPairFound");
      pairFound.style.display = "none";
      let pairNotFound = document.getElementById("tokenPairNotFound");
      pairNotFound.classList.add("fadeIn");
      pairNotFound.style.display = "block";
	  }
	}
	if (switcher == "1") {
	  if (data['message'] == "OK") {
	    let parsed1Abi = JSON.parse(data['result']);
	    this.setState({token1Abi: parsed1Abi, token1Address: token_address});
	    console.log ("Token1 Contract ABI has been loaded successfully.");
	    this.getToken1Symbol ();
	  } else {
	    this.setState({token1Abi: '', token1Address: '', token1Symbol: ''});
	    console.log ("Token1 Contract ABI can not be loaded.");
      let pairFound = document.getElementById("tokenPairFound");
      pairFound.style.display = "none";
      let pairNotFound = document.getElementById("tokenPairNotFound");
      pairNotFound.classList.add("fadeIn");
      pairNotFound.style.display = "block";
	  }
	}
    })
    .catch(error => console.log(error.message));
  }
  getPairDetails () {
    this.getToken0Address ();
    setTimeout(() => { this.getToken1Address(); }, 500);
    setTimeout(() => { this.getTokenTotalSupply (); }, 3000);
  }
  getToken0Address () {
    let contractTmp = new web3.eth.Contract(this.state.tokenAbi, this.state.tokenAddress);
    try {
        contractTmp.methods.token0().call().then((result) => {
          console.log ("token0 address: ", result);
          let token_0_address = result;
          this.setState({ token0Address: token_0_address }, () => {
    		      setTimeout(() => { this.getTokenContractAbi(token_0_address, 0); }, 500);
	        });
        });
    } catch (error) {
        console.log(error);
        this.notLpGiven ();
    }
  }
  getToken1Address () {
    let contractTmp = new web3.eth.Contract(this.state.tokenAbi, this.state.tokenAddress);
    try {
        contractTmp.methods.token1().call().then((result) => {
          console.log ("token1 address: ", result);
          let token_1_address = result;
          this.setState({ token1Address: token_1_address }, () => {
    		      setTimeout(() => { this.getTokenContractAbi(token_1_address, 1); }, 1000);
	        });
        });
    } catch (error) {
        console.log(error);
        this.notLpGiven ();
    }
  }
  getToken0Symbol () {
    let contractTmp = new web3.eth.Contract(this.state.token0Abi, this.state.token0Address);
    try {
        contractTmp.methods.symbol().call().then((result) => {
          console.log ("token0 symbol: ", result);
          let token_0_symbol = result;
          this.setState({ token0Symbol: token_0_symbol }, () => {});
        });
    } catch (error) {
        console.log(error);
    }
  }
  getToken1Symbol () {
    let contractTmp = new web3.eth.Contract(this.state.token1Abi, this.state.token1Address);
    try {
        contractTmp.methods.symbol().call().then((result) => {
          console.log ("token1 symbol: ", result);
          let token_1_symbol = result;
          this.setState({ token1Symbol: token_1_symbol }, () => {});
        });
    } catch (error) {
        console.log(error);
    }
  }
  getTokenTotalSupply () {
    let contractTmp = new web3.eth.Contract(this.state.tokenAbi, this.state.tokenAddress);
    try {
        contractTmp.methods.totalSupply().call().then((result) => {
          console.log ("Main Token TotalSupply: ", result);
          let total_supply = result;
	        let total_supply_in_eth = web3.utils.fromWei(total_supply, 'ether');
          console.log ("Main Token TotalSupply in ETH: ", total_supply_in_eth);
          this.setState({ tokenTotalSupply: total_supply, tokenTotalSupplyInEth: total_supply_in_eth }, () => {
            this.getAmountInContract ();
          });
        });
    } catch (error) {
        console.log(error);
    }
  }
  setLockLiquidityView () {
    this.setState({ tokenAdmin: true }, () => {
      this.silentConnectMetamask ();
    });
    let start = document.getElementById("startPoint");
    start.style.display = "none";
    setTimeout(() => {
      let connectBlock = document.getElementById("connect-block");
      connectBlock.classList.add("fadeIn");
      connectBlock.style.display = "block";
    }, 100);
  }
  setCheckLiquidityView () {
    let start = document.getElementById("startPoint");
    start.style.display = "none";
    setTimeout(() => {
      let readOnly = document.getElementById("readOnly");
      readOnly.classList.add("fadeIn");
      readOnly.style.display = "block";
    }, 100);
  }
  setStartViewFromCheckLiquidityView () {
    this.setState({ tokenAddress: '', tokenAbi: '', token0Abi: '', token0Address: '', token0Symbol: '', token1Abi: '', token1Address: '', token1Symbol: '', tokenTotalSupply: '', tokenTotalSupplyInEth: '', tokenBasedlockSupply: '', tokenBasedlockSupplyInEth: '', tokenUnlockTime: '', tokenUnlockTimeInUtc: '', currentTime: '', timeToUnlock: '', timeToUnlockInDays: '', statusImg: '' }, () => {});
    let readOnly = document.getElementById("readOnly");
    readOnly.style.display = "none";
    setTimeout(() => {
      let url = "/";
      window.location.href = url;
    }, 100);
  }
  handlePairAddressChange (event) {
    let tempData = event.target.value;
    this.setState({ tokenAddress: tempData });
  }
  getLockData () {
    this.setState({ token0Abi: '', token0Address: '', token0Symbol: '', token1Abi: '', token1Address: '', token1Symbol: '', tokenTotalSupply: '', tokenTotalSupplyInEth: '', tokenBasedlockSupply: '', tokenBasedlockSupplyInEth: '', tokenUnlockTime: '', tokenUnlockTimeInUtc: '', currentTime: '', timeToUnlock: '', timeToUnlockInDays: '', statusImg: '', lockPercent: '' }, () => {
      //let pairNotFound = document.getElementById("tokenPairNotFound");
      //pairNotFound.style.display = "none";
      //this.getTokenContractAbi(this.state.tokenAddress, 10);
      let url = "/?token=" + this.state.tokenAddress;
      setTimeout(function(){ window.location.href = url; }, 100);
    });
  }
  getAmountInContract () {
    try {
        contract.methods.getTokenAmount(this.state.tokenAddress).call().then((result) => {
          console.log ("Main Token Amount in Contract: ", result);
          let supply = result;
	        let supply_in_eth = web3.utils.fromWei(supply, 'ether');
          this.setState({ tokenBasedlockSupply: supply, tokenBasedlockSupplyInEth: supply_in_eth }, () => {
            this.getAmountInContractPercentage ();
          });
        });
    } catch (error) {
        console.log(error);
    }
  }
  getAmountInContractPercentage () {
    let res;
    if (this.state.tokenBasedlockSupply == '0') {
      res = 0;
    } else {
      let basedSupplyNr = Number(this.state.tokenBasedlockSupply);
      let totalSupplyNr = Number(this.state.tokenTotalSupply);
      res = (basedSupplyNr / totalSupplyNr) * 100;
    }
    this.setState({ lockPercent: res }, () => {
      this.getTokenUnlockTime ();
    });
  }
  getTokenUnlockTime () {
    try {
        contract.methods.getUnlockTime(this.state.tokenAddress).call().then((result) => {
          console.log ("UnlockTime in UNIX time: ", result);
          let unlockTime = Number(result);
          let unlockTimeUtc;
          if (unlockTime > 0) {
            let unlockTimeMs = unlockTime * 1000;
            let date = new Date(unlockTimeMs);
            unlockTimeUtc = date.getHours() + ":" + date.getMinutes() + ", "+ date.toDateString();
          } else {
            unlockTimeUtc = "no lock found";
            let lpUnlockTime = document.getElementById("lpUnlockTime");
            lpUnlockTime.classList.add("redFont");
          }
          this.setState({ tokenUnlockTime: unlockTime, tokenUnlockTimeInUtc: unlockTimeUtc }, () => {
            this.getCurrentTime ();
          });
        });
    } catch (error) {
        console.log(error);
    }
  }
  getCurrentTime () {
    try {
        contract.methods.getCurrentTime().call().then((result) => {
          console.log ("CurrentTime in UNIX time: ", result);
          let currentTimeNow = result;
          this.setState({ currentTime: currentTimeNow }, () => {
            this.getTimeToUnlock ();
          });
        });
    } catch (error) {
        console.log(error);
    }
  }
  getTimeToUnlock () {
    let timeUnlock = Number(this.state.tokenUnlockTime);
    let timeCurrent = Number(this.state.currentTime);
    let r;
    let res;
    if (timeUnlock > timeCurrent) {
      let diff = timeUnlock - timeCurrent;
      r = diff;
      let minutes = diff / 60;
      let hours = minutes / 60;
      let days = hours / 24;
      if (days > 1) {
        res = days + " days";
      } else {
        res = "in a day";
      }
    } else {
      r = 0;
      if (this.state.tokenBasedlockSupply == '0') {
        res = "no lock found";
      } else {
        res = "unlocked";
      }
      let lpTimeToUnlock = document.getElementById("lpTimeToUnlock");
      lpTimeToUnlock.classList.add("redFont");
    }
    this.setState({ timeToUnlock: r, timeToUnlockInDays: res }, () => {
      this.getCheckMark ();
      if (this.state.tokenAdmin === true) {
        setTimeout(() => { this.getTokenBalanceInOwnerWallet(); }, 1000);
      }
    });
  }
  getCheckMark () {
    let r = this.state.timeToUnlock;
    let res;
    if (r > 0) {
      if (this.state.lockPercent > 94) {
        res = "./status-ok.png";
      } else {
        res = "./status-notok.png";
        let lpAtBased = document.getElementById("lpAtBased");
        lpAtBased.classList.add("redFont");
      }
    } else {
      res = "./status-notok.png";
      let lpAtBased = document.getElementById("lpAtBased");
      lpAtBased.classList.add("redFont");
    }
    this.setState({ statusImg: res }, () => {});
  }
  getLockFee () {
    try {
        contract.methods.getLockFee().call().then((result) => {
          console.log ("Lock Fee in wei: ", result);
          let lock_fee = result;
          let lock_fee_in_eth = web3.utils.fromWei(lock_fee, 'ether');
          this.setState({ lockFee: lock_fee, lockFeeInEth: lock_fee_in_eth }, () => {
            this.payFee ();
          });
        });
    } catch (error) {
        console.log(error);
    }
  }
  checkConnectionAfterConnect () {
    let nr = Number(this.state.connectionControlNumber);
    let oldWallet = this.state.account;
    if (this.state.tokenAdmin === true) {
      if (nr < 100) {
        let new_nr = nr + 1;
        if (window.ethereum) {
          let wallet = window.ethereum.selectedAddress;
          if (wallet != '0x0000000000000000000000000000000000000000' && wallet != '' && wallet != undefined && wallet != null) {
            this.setState({ connectionControlNumber: new_nr }, () => {
              this.silentConnectMetamask ();
              if (wallet != oldWallet) {
                setTimeout(() => {
                  this.checkConnectionAfterConnect ();
                }, 2000);
              }
            });
          } else {
            this.setState({ connectionControlNumber: new_nr }, () => {
              setTimeout(() => {
                this.checkConnectionAfterConnect ();
              }, 2000);
            });
          }
        }
      }
    }
  }
  startLockData () {
    let lpAtBased = document.getElementById("lpAtBased");
    lpAtBased.classList.remove("redFont");
    let lpUnlockTime = document.getElementById("lpUnlockTime");
    lpUnlockTime.classList.remove("redFont");
    let lpTimeToUnlock = document.getElementById("lpTimeToUnlock");
    lpTimeToUnlock.classList.remove("redFont");
    this.setState({ token0Abi: '', token0Address: '', token0Symbol: '', token1Abi: '', token1Address: '', token1Symbol: '', tokenTotalSupply: '', tokenTotalSupplyInEth: '', tokenBasedlockSupply: '', tokenBasedlockSupplyInEth: '', tokenUnlockTime: '', tokenUnlockTimeInUtc: '', currentTime: '', timeToUnlock: '', timeToUnlockInDays: '', statusImg: '', lockPercent: '', tokenBalanceInOwnerWallet: '', tokenBalanceInOwnerWalletInEth: '' }, () => {
      this.getTokenContractAbi(this.state.tokenAddress, 10);
    });
  }
  getTokenBalanceInOwnerWallet () {
    if (this.state.tokenAdmin === true) {
      if(window.ethereum) {
        let wallet = window.ethereum.selectedAddress;
        try {
            let contractTmp = new web3.eth.Contract(this.state.tokenAbi, this.state.tokenAddress);
            contractTmp.methods.balanceOf(wallet).call().then((result) => {
              console.log ("Owner has (LP tokens): ", result);
              let balance = result;
    	        let balance_in_eth = web3.utils.fromWei(balance, 'ether');
              this.setState({ tokenBalanceInOwnerWallet: balance, tokenBalanceInOwnerWalletInEth: balance_in_eth }, () => {
                setTimeout(() => {
                  let tokenBalanceOwner = document.getElementById("tokenBalanceOwner");
                  tokenBalanceOwner.classList.add("fadeIn");
                  tokenBalanceOwner.style.display = "block";
                  this.getTokenOwner ();
                }, 1000);
                setTimeout(() => { this.scrollDown (); }, 1200);
                setTimeout(() => { this.scrollDown (); }, 1500);
              });
            });
        } catch (error) {
            console.log(error);
        }
      }
    }
  }
  scrollDown () {
    setTimeout(() => {
      window.scrollTo({ left: 0, top: document.body.scrollHeight, behavior: "smooth" });
    }, 200);
  }
  getTokenOwnerPercentage () {

  }
  getTokenPercentageOnTheFly () {

  }
  setTokenPercentageTo95 () {
    let total_supply = Number(this.state.tokenTotalSupply);
    let owner_supply = Number(this.state.tokenBalanceInOwnerWallet);
    let p01 = owner_supply / 100;
    let p95 = p01 * 95;
    let supply_to_set = Math.ceil(p95);
    this.setState({ tokenAmountToLock: supply_to_set });
  }
  setTokenPercentageToMax () {
    let total_supply = Number(this.state.tokenTotalSupply);
    let owner_supply = Number(this.state.tokenBalanceInOwnerWallet);
    this.setState({ tokenAmountToLock: owner_supply });
  }
  notLpGiven () {
    this.setState({ token0Symbol: 'NaN', token1Symbol: 'NaN' }, () => {});
  }
  handleTokenAmountToLockChange (event) {
    let tempDataRaw = event.target.value;
    let tempData = tempDataRaw.replace(/[^0-9]/g, '');
    tempData = tempData.replace(/^0+/, "");
    this.setState({ tokenAmountToLock: tempData });
  }
  getTokenOwner () {
    try {
        contract.methods.getTokenOwner(this.state.tokenAddress).call().then((result) => {
          console.log ("TokenOwner Address: ", result);
          let token_owner = result;
          this.setState({ tokenOwner: token_owner }, () => {
            let tokenBalanceOwnerNotMatch = document.getElementById("tokenBalanceOwnerNotMatch");
            let inputTokenAmount = document.getElementById("inputTokenAmount");
            let inputLockTime = document.getElementById("inputLockTime");
            let startLockButton = document.getElementById("startLockButton");
            let extendLockButton = document.getElementById("extendLockButton");
            let extendLockTimeButton = document.getElementById("extendLockTimeButton");
            let withdrawLockButton = document.getElementById("withdrawLockButton");
            if (token_owner != "0x0000000000000000000000000000000000000000") {
              if (this.state.account.toLowerCase() != token_owner.toLowerCase()) {
                inputTokenAmount.style.display = "none";
                inputLockTime.style.display = "none";
                startLockButton.style.display = "none";
                extendLockButton.style.display = "none";
                extendLockTimeButton.style.display = "none";
                tokenBalanceOwnerNotMatch.classList.add("fadeIn");
                tokenBalanceOwnerNotMatch.style.display = "block";
              } else {
                tokenBalanceOwnerNotMatch.style.display = "none";
                inputTokenAmount.classList.add("fadeIn");
                inputTokenAmount.style.display = "block";
                inputLockTime.classList.add("fadeIn");
                inputLockTime.style.display = "block";
                if (this.state.tokenBasedlockSupply > 0) {
                  startLockButton.style.display = "none";
                  extendLockButton.classList.add("fadeIn");
                  extendLockButton.style.display = "block";
                  extendLockTimeButton.classList.add("fadeIn");
                  extendLockTimeButton.style.display = "block";
                  if(this.state.timeToUnlockInDays == "unlocked") {
                    withdrawLockButton.classList.add("fadeIn");
                    withdrawLockButton.style.display = "block";
                  } else {
                    withdrawLockButton.style.display = "none";
                  }
                } else {
                  extendLockButton.style.display = "none";
                  extendLockTimeButton.style.display = "none";
                  startLockButton.classList.add("fadeIn");
                  startLockButton.style.display = "block";
                }
              }
            } else {
              tokenBalanceOwnerNotMatch.style.display = "none";
              inputTokenAmount.classList.add("fadeIn");
              inputTokenAmount.style.display = "block";
              inputLockTime.classList.add("fadeIn");
              inputLockTime.style.display = "block";
              if (this.state.tokenBasedlockSupply > 0) {
                startLockButton.style.display = "none";
                extendLockButton.classList.add("fadeIn");
                extendLockButton.style.display = "block";
                extendLockTimeButton.classList.add("fadeIn");
                extendLockTimeButton.style.display = "block";
                if(this.state.timeToUnlockInDays == "unlocked") {
                  withdrawLockButton.classList.add("fadeIn");
                  withdrawLockButton.style.display = "block";
                } else {
                  withdrawLockButton.style.display = "none";
                }
              } else {
                extendLockButton.style.display = "none";
                extendLockTimeButton.style.display = "none";
                startLockButton.classList.add("fadeIn");
                startLockButton.style.display = "block";
              }
            }
          });
        });
    } catch (error) {
        console.log(error);
    }
  }
  handleLockLengthChange (event) {
    let tempDataRaw = event.target.value;
    let tempData = tempDataRaw.replace(/[^0-9]/g, '');
    tempData = tempData.replace(/^0+/, "");
    this.setState({ lockLengthInput: tempData });
  }
  startTheLock () {
    let days;
    let lockTime;
    let unlockTime;
    let amount;
    let tokenContractAddress = this.state.tokenAddress;
    let currentTime = Number(this.state.currentTime);
    let lengthInput = Number(this.state.lockLengthInput);
    let amountToLock = Number(this.state.tokenAmountToLock);
    if (this.state.lockLengthInput != '' && this.state.tokenAmountToLock != '') {
      if (lengthInput > 0) {
        days = lengthInput;
      } else {
        days = 1;
      }
      if (amountToLock > 0) {
        amount = amountToLock;
      } else {
        amount = 1;
      }
      lockTime = days * 86400;
      unlockTime = currentTime + lockTime;
      try {
        if(window.ethereum) {
        	let wallet = window.ethereum.selectedAddress;
          let lockFeeToPay = this.state.lockFee;
        	window.ethereum.enable();
          contract2.methods.lockToken(tokenContractAddress, BigInt(amount), unlockTime).send({ address: deployedContractAddress, from: wallet, value: lockFeeToPay }).then((tx) => {
              console.log("Lock successful: ", tx);
              let visitCertificate = document.getElementById("visitCertificate");
              visitCertificate.classList.add("fadeIn");
              visitCertificate.style.display = "block";
              this.scrollDown ();
            }).catch(err => {
              console.log(err);
            });
        }
      } catch (error) {
          console.log(error);
      }
    }
  }
  visitCertificate () {
    let url = "/?token=" + this.state.tokenAddress;
    setTimeout(function(){ window.location.href = url; }, 100);
  }
  extendTheLock () {
    let unlockTime;
    let amount;
    let tokenContractAddress = this.state.tokenAddress;
    let currentTime = Number(this.state.currentTime);
    let amountToLock = Number(this.state.tokenAmountToLock);
    if (this.state.tokenAmountToLock != '') {
      if (amountToLock > 0) {
        amount = amountToLock;
      } else {
        amount = 1;
      }
      unlockTime = currentTime + 620000;
      try {
        if(window.ethereum) {
        	let wallet = window.ethereum.selectedAddress;
        	window.ethereum.enable();
          contract2.methods.lockToken(tokenContractAddress, BigInt(amount), unlockTime).send({ address: deployedContractAddress, from: wallet }).then((tx) => {
              console.log("Lock Amount Extension successful: ", tx);
              let visitCertificate = document.getElementById("visitCertificate");
              visitCertificate.classList.add("fadeIn");
              visitCertificate.style.display = "block";
              this.scrollDown ();
            }).catch(err => {
              console.log(err);
            });
        }
      } catch (error) {
          console.log(error);
      }
    }
  }
  extendTheLockTime () {
    let days;
    let lockTime;
    let unlockTime;
    let tokenContractAddress = this.state.tokenAddress;
    let currentTime = Number(this.state.currentTime);
    let lengthInput = Number(this.state.lockLengthInput);
    let currentUnlockTime = Number(this.state.tokenUnlockTime);
    if (this.state.lockLengthInput != '') {
      if (lengthInput > 0) {
        days = lengthInput;
      } else {
        days = 1;
      }
      lockTime = days * 86400;
      if (currentTime > currentUnlockTime) {
        unlockTime = currentTime + lockTime;
      } else {
        unlockTime = currentUnlockTime + lockTime;
      }
      try {
        if(window.ethereum) {
        	let wallet = window.ethereum.selectedAddress;
        	window.ethereum.enable();
          contract2.methods.extendLockTime(tokenContractAddress, unlockTime).send({ address: deployedContractAddress, from: wallet }).then((tx) => {
              console.log("Lock Time Extension successful: ", tx);
              let visitCertificate = document.getElementById("visitCertificate");
              visitCertificate.classList.add("fadeIn");
              visitCertificate.style.display = "block";
              this.scrollDown ();
            }).catch(err => {
              console.log(err);
            });
        }
      } catch (error) {
          console.log(error);
      }
    }
  }
  approveLpTokenStartLock () {
    let contractTmp = new web4.eth.Contract(this.state.tokenAbi, this.state.tokenAddress);
    try {
      if(window.ethereum) {
        let wallet = window.ethereum.selectedAddress;
        let amountToApprove = this.state.tokenAmountToLock;
        if (amountToApprove == '0') {amountToApprove = '1';}
        contractTmp.methods.approve(deployedContractAddress, amountToApprove).send({ from: wallet }).then((tx) => {
          console.log("Approve success: ", tx);
          this.startTheLock ();
        });
      }
    } catch (error) {
        console.log(error);
    }
  }
  approveLpTokenExtendLock () {
    let contractTmp = new web4.eth.Contract(this.state.tokenAbi, this.state.tokenAddress);
    try {
      if(window.ethereum) {
        let wallet = window.ethereum.selectedAddress;
        let amountToApprove = this.state.tokenAmountToLock;
        if (amountToApprove == '0') {amountToApprove = '1';}
        contractTmp.methods.approve(deployedContractAddress, amountToApprove).send({ from: wallet }).then((tx) => {
          console.log("Approve success: ", tx);
          this.extendTheLock ();
        });
      }
    } catch (error) {
        console.log(error);
    }
  }
  approveLpTokenWithdrawTokens () {
    let contractTmp = new web4.eth.Contract(this.state.tokenAbi, this.state.tokenAddress);
    try {
      if(window.ethereum) {
        let wallet = window.ethereum.selectedAddress;
        let amountToApprove = this.state.tokenAmountToLock;
        if (amountToApprove == '0') {amountToApprove = '1';}
        contractTmp.methods.approve(deployedContractAddress, amountToApprove).send({ from: wallet }).then((tx) => {
          console.log("Approve success: ", tx);
          this.withdrawTokens ();
        });
      }
    } catch (error) {
        console.log(error);
    }
  }
  withdrawTokens () {
    try {
      if(window.ethereum) {
        let wallet = window.ethereum.selectedAddress;
        let tokenContractAddress = this.state.tokenAddress;
        window.ethereum.enable();
        contract2.methods.withdrawToken(tokenContractAddress).send({ address: deployedContractAddress, from: wallet }).then((tx) => {
            console.log("Withraw successful: ", tx);
          }).catch(err => {
            console.log(err);
          });
      }
    } catch (error) {
        console.log(error);
    }
  }
  payFee () {
    try {
      if(window.ethereum) {
        let wallet = window.ethereum.selectedAddress;
        contract.methods.getWalletAddressPayFeeOnLock(wallet).call().then((result) => {
          console.log ("PayFee: ", result);
          if (result === false) {
            this.setState({ lockFee: 0, lockFeeInEth: 0 }, () => {});
          }
        });
      }
    } catch (error) {
        console.log(error);
    }
  }

  componentDidMount() {
    this.checkUrlForTokenAddress ();
    /* setTimeout(() => {
      this.metamaskInstalled();
      this.silentConnectMetamask();
    }, 1000); */
    //this.getTokenContractAbi("0xf0ed393714f03bbddf7d6817c0f31d1a7224e4da", 10);
  }

  render() {
    return (
      <div>
      <div className="container-fluid">
      <div className="blueBg">
      <div className="spacer20px" />
      <div className="row">
      <div className="col-md-6">
      <div className="logoHolder"><img src="./base_wordmark_white.svg" alt="BASE logo" /></div>
      </div>
      <div className="col-md-6">
      <div className="headLine whiteFont">BasedLock.com</div>
      </div>
      </div>
      <div className="spacer20px" />
      <div className="row">
      <div className="col-md-12">
      <h1 className="headLineSub whiteFont">The first liquidity locker on the BASE chain</h1>
      </div>
      </div>
      <div className="spacer20px" />
      </div>
      </div>
      <div className="App-header bg">
      <Head />
      <Container className="App">

      <div id="feedbackMessage">
      <div className="alert-box not-loggedin-metamask">Please login to MetaMask</div>
      <div className="alert-box metamask-login">Logged in to MetaMask</div>
      </div>

      <div className="spacer5px" />
      <div className="row">
      <div className="col-md-12 cen">
      <hr />
      <div id="startPoint">
      <div className="spacer20px" />
      <div className="row">
      <div className="col-md-12">
      <p className="smallFont">Please select an option to start:</p>
      </div>
      </div>
      <div className="row">
      <div className="col-md-2"></div>
      <div className="col-md-3">
      <button className="btn btn-base btn-lg px-4">
      <span className="btn-text" onClick={this.setLockLiquidityView}>Lock / Withdraw</span>
      </button>
      </div>
      <div className="col-md-2"></div>
      <div className="col-md-3">
      <button className="btn btn-base btn-lg px-4">
      <span className="btn-text" onClick={this.setCheckLiquidityView}>Check Liquidity</span>
      </button>
      </div>
      <div className="col-md-2"></div>
      </div>
      <div className="spacer20px" />
      <div className="row">
      <div className="col-md-12">
      <div className="spacer20px" />
      <hr />
      <div className="spacer20px" />
      <p>The first LP lock on the BASE chain! <br />Lock liquidity tokens for 0.01 ETH!</p>
      <div className="spacer20px" />
      <p className="lefted"><u>FAQ:</u></p>
      <p className="lefted"><strong>What can I lock here?</strong> <br />- V2 Liquidity pair tokens can be locked here on BASE.</p>
      <p className="lefted"><strong>Where can I check if my liquidity pair tokens are supported?</strong> <br />- Check compatibility here quickly: <a className="lblue" href="https://basedlock.com/?token=0x">check liquidity</a>. If you see the paired token names and the number of total LP tokens then it's supported!</p>
      <p className="lefted"><strong>Where can I find the pair address?</strong> <br />- The easiest way is to get it on <a className="lblue" href="https://www.dextools.io/app/en/pairs" target="_blank" >dextools</a> or <a className="lblue" href="https://dexscreener.com/" target="_blank" >dexscreener</a>.</p>
      <p className="lefted"><strong>Can I lock simple ERC20 tokens here?</strong> <br />- No, currently only LP tokens are supported.</p>
      <p className="lefted"><strong>What is the minimum lock time?</strong> <br />- 1 day.</p>
      <p className="lefted"><strong>What is the maximum lock time?</strong> <br />- Not limited.</p>
      <p className="lefted"><strong>How many locks can I have on my account?</strong> <br />- You can have several different pair locks with the same wallet address.</p>
      <p className="lefted"><strong>Can I create locks for the same pair from different accounts?</strong> <br />- No, only 1 wallet address can manage an active lock.</p>
      <p className="lefted"><strong>Why is there no multiple locks?</strong> <br />- Generally there is only 1 dev on a project and 95% of the LP tokens should be locked.</p>
      <p className="lefted"><strong>Can I add more tokens to the pair once I have locked it?</strong> <br />- Yes, you can add more LP tokens to the locked pair during the active lock period.</p>
      <p className="lefted"><strong>Can I extend the lock time?</strong> <br />- Yes, you can extend it during the active lock period.</p>
      <p className="lefted"><strong>Can I relock the tokens if the lock expired?</strong> <br />- Yes, you can relock it by adding more days to it.</p>
      <p className="lefted"><strong>What is the price of this service?</strong> <br />- You have to pay the gas fees and the price of the initial lock. It's 0.01 ETH. The relock and extensions are free (only gas fee).</p>
      <p className="lefted"><strong>Who can withdraw the LP tokens when lock expired?</strong> <br />- The owner of the lock. A new lock can have a new owner after the withdrawal is completed. The lock price has to be paid once again after withdrawal.</p>
      <p className="lefted"><strong>Who can withdraw the LP tokens when lock is active?</strong> <br />- Nobody.</p>
      <p className="lefted"><strong>Is there a way to check locks by BasedLock.com?</strong> <br />- Yes, every liquidity pairs can be queried on the BASE chain.</p>
      <p className="lefted"><strong>How can I share the lock status page from BasedLock.com?</strong> <br />- Just copy&paste the address from the url bar after a successful "Check Liquidity".</p>
      <p className="lefted"><strong>Do I have to connect wallet to check locks?</strong> <br />- No.</p>
      <p className="lefted"><strong>Do I have to connect wallet to create a new lock or edit it?</strong> <br />- Yes.</p>
      <p className="lefted"><strong>The lock does not work. The website is not communicating to Metamask.</strong> <br />- Be sure you are on the BASE chain.</p>
      <p className="lefted"><strong>How can I switch to BASE in my Metamask?</strong> <br />- <a className="lblue" href="https://docs.base.org/using-base/" target="_blank">https://docs.base.org/using-base/</a></p>
      <p className="lefted"><strong>What is needed to have green checkmark when checking a lock?</strong> <br />- More than 94% of the pair token has to be in active lock (not expired lock).</p>
      <p className="lefted"><strong>What is the smart contract address of the lock?</strong> <br />- <a className="lblue" href="https://basescan.org/address/0xd39bd1c7a994edc928339f130e4bca46ef586f68#code" target="_blank">0xd39bd1c7a994edc928339f130e4bca46ef586f68</a></p>
      <p className="lefted"><strong>Do you support only BASE chain?</strong> <br />- No, we support the opBNB chain too. Visit: <a className="lblue" href="https://oplock.org" target="_blank" rel="nofollow">https://oplock.org</a></p>
      <div className="spacer20px" />
      <hr />
      </div>
      </div>
      </div>
      <div id="connect-block">
      <div className="row">
      <div className="col-md-3"></div>
      <div className="col-md-6">
      <div className="spacer5px" />
      <button className="btn btn-base">
      <span className="btn-text" onClick={this.setStartViewFromCheckLiquidityView}>GO BACK</span>
      </button>
      </div>
      <div className="col-md-3"></div>
      </div>
      <div className="row">
      <div className="col-md-3"></div>
      <div className="col-md-6">
      <div className="spacer5px" />
      <div className="spacer5px" />
      <p className="smallFont">Metamask and Coinbase Wallet supported</p>
      <button className="btn btn-base btn-lg px-4">
      <span className="btn-text" onClick={this.connectMetamask}>{this.state.connectButtonText}</span>
      </button>
      </div>
      <div className="col-md-3"></div>
      </div>
      </div>

      <div id="connectionStatus">
      <div className="spacer20px" />
      <div className="row">
      <div className="col-md-12">
      <p className="smallFont">Wallet Connected: <br /><span className="blackFont">{this.state.account}</span></p>
      </div>
      </div>
      </div>

      <div id="whenConnected">
      <div className="spacer20px" />
      <div className="row">
      <div className="col-md-3"></div>
      <div className="col-md-6">
      <div className="spacer20px" />
      <div className="form-group">
      <input type="text" className="form-control" onChange={this.handlePairAddressChange} value={this.state.tokenAddress} id="enterPairAddressOwner" placeholder="enter pair address" />
      </div>
      </div>
      <div className="col-md-3"></div>
      </div>
      <div className="row">
      <div className="col-md-3"></div>
      <div className="col-md-6">
      <div className="spacer20px" />
      <button className="btn btn-base">
      <span className="btn-text" onClick={this.startLockData}>START</span>
      </button>
      </div>
      <div className="col-md-3"></div>
      </div>
      </div>

      <div id="whenNotConnected">
      <div className="row">
      <div className="col-md-12">
      <p className="smallFont">please connect your wallet to continue</p>
      </div>
      </div>
      </div>

      <div id="readOnly">
      <div className="row">
      <div className="col-md-3"></div>
      <div className="col-md-6">
      <div className="spacer5px" />
      <button className="btn btn-base">
      <span className="btn-text" onClick={this.setStartViewFromCheckLiquidityView}>GO BACK</span>
      </button>
      </div>
      <div className="col-md-3"></div>
      </div>
      <div className="row">
      <div className="col-md-3"></div>
      <div className="col-md-6">
      <div className="spacer20px" />
      <div className="form-group">
      <input type="text" className="form-control" onChange={this.handlePairAddressChange} value={this.state.tokenAddress} id="enterPairAddress" placeholder="enter pair address" />
      </div>
      </div>
      <div className="col-md-3"></div>
      </div>
      <div className="row">
      <div className="col-md-3"></div>
      <div className="col-md-6">
      <div className="spacer20px" />
      <button className="btn btn-base btn-lg px-4">
      <span className="btn-text" onClick={this.getLockData}>CHECK DATA</span>
      </button>
      </div>
      <div className="col-md-3"></div>
      </div>
      </div>

      <div id="tokenPairNotFound">
      <div className="row">
      <div className="col-md-3"></div>
      <div className="col-md-6">
      <div className="spacer20px" />
      <p className="smallFont">Sorry! We can not query your request!</p>
      <p className="smallFont">Possible reasons:</p>
      <p className="smallFont">- wrong LP token address entered</p>
      <p className="smallFont">- your token is not verified on basescan.org</p>
      <p className="smallFont">- you are not connected to the BASE chain</p>
      <p className="smallFont">- server is overloaded, please try again later</p>
      </div>
      <div className="col-md-3"></div>
      </div>
      </div>

      <div id="tokenPairFound">
      <div className="row">
      <div className="col-md-3"></div>
      <div className="col-md-6">
      <div className="spacer20px" />
      <p>Pair: {this.state.token0Symbol} / {this.state.token1Symbol}</p>
      <p>Total LP tokens: {this.state.tokenTotalSupplyInEth}</p>
      <p id="lpAtBased">LP tokens at BasedLock: {this.state.tokenBasedlockSupplyInEth} ({this.state.lockPercent}%)</p>
      <p id="lpUnlockTime">Unlock Time: {this.state.tokenUnlockTimeInUtc}</p>
      <p id="lpTimeToUnlock">Time to Unlock: {this.state.timeToUnlockInDays}</p>
      <p id="statusImgHolder"><img className="statusOkNotok" src={this.state.statusImg} /></p>
      </div>
      <div className="col-md-3"></div>
      </div>
      </div>

      <div id="tokenBalanceOwner">
      <div className="row">
      <div className="col-md-3"></div>
      <div className="col-md-6">
      <div className="spacer20px" />
      <p>Token balance in your wallet: {this.state.tokenBalanceInOwnerWalletInEth} <br />Token balance in wei format: {this.state.tokenBalanceInOwnerWallet}</p>
      </div>
      <div className="col-md-3"></div>
      </div>
      </div>

      <div id="withdrawLockButton">
      <div className="row">
      <div className="col-md-3"></div>
      <div className="col-md-6">
      <div className="spacer20px" />
      <div className="spacer20px" />
      <div className="spacer20px" />
      <button className="btn btn-base">
      <span className="btn-text" onClick={this.withdrawTokens}>WITHDRAW LP TOKENS</span>
      </button>
      </div>
      <div className="col-md-3"></div>
      </div>
      <div className="spacer20px" />
      </div>

      <div id="tokenBalanceOwnerNotMatch">
      <div className="row">
      <div className="col-md-3"></div>
      <div className="col-md-6">
      <div className="spacer20px" />
      <p>It seems you are not the token owner. <br />The token owner is {this.state.tokenOwner}</p>
      </div>
      <div className="col-md-3"></div>
      </div>
      </div>

      <div id="inputTokenAmount">
      <div className="row">
      <div className="col-md-3"></div>
      <div className="col-md-6">
      <div className="spacer20px" />
      <div className="spacer20px" />
      <div className="spacer20px" />
      <p className="smallFont">Set the amount of LP tokens to lock</p>
      <div className="form-group">
      <input type="text" className="form-control" onChange={this.handleTokenAmountToLockChange} value={this.state.tokenAmountToLock} id="enterLpAmount" placeholder="enter amount in wei" />
      </div>
      <p className="smallFont">(You can add more LP tokens here if lock is active)</p>
      </div>
      <div className="col-md-3"></div>
      </div>
      <div className="row">
      <div className="col-md-3"></div>
      <div className="col-md-3">
      <div className="spacer5px" />
      <button className="btn btn-base">
      <span className="btn-text" onClick={this.setTokenPercentageTo95}>SET 95%</span>
      </button>
      </div>
      <div className="col-md-3">
      <div className="spacer5px" />
      <button className="btn btn-base">
      <span className="btn-text" onClick={this.setTokenPercentageToMax}>SET MAX</span>
      </button>
      </div>
      <div className="col-md-3"></div>
      </div>
      </div>

      <div id="extendLockButton">
      <div className="row">
      <div className="col-md-3"></div>
      <div className="col-md-6">
      <div className="spacer20px" />
      <div className="spacer20px" />
      <div className="spacer20px" />
      <button className="btn btn-base">
      <span className="btn-text" onClick={this.approveLpTokenExtendLock}>APPROVE AND EXTEND LOCK LP</span>
      </button>
      </div>
      <div className="col-md-3"></div>
      </div>
      <div className="spacer20px" />
      </div>

      <div id="inputLockTime">
      <div className="row">
      <div className="col-md-3"></div>
      <div className="col-md-6">
      <div className="spacer20px" />
      <div className="spacer20px" />
      <div className="spacer20px" />
      <p className="smallFont">Set the lock length in days (min. 1 day)</p>
      <div className="form-group">
      <input type="text" className="form-control" onChange={this.handleLockLengthChange} value={this.state.lockLengthInput} id="enterLockLength" placeholder="length in days" />
      </div>
      <p className="smallFont">(You can add more days here if lock is active)</p>
      </div>
      <div className="col-md-3"></div>
      </div>
      </div>

      <div id="startLockButton">
      <div className="row">
      <div className="col-md-3"></div>
      <div className="col-md-6">
      <div className="spacer20px" />
      <div className="spacer20px" />
      <p className="smallFont">lock fee: {this.state.lockFeeInEth} eth</p>
      <div className="spacer20px" />
      <button className="btn btn-base">
      <span className="btn-text" onClick={this.approveLpTokenStartLock}>APPROVE AND LOCK LP</span>
      </button>
      </div>
      <div className="col-md-3"></div>
      </div>
      <div className="spacer20px" />
      </div>

      <div id="extendLockTimeButton">
      <div className="row">
      <div className="col-md-3"></div>
      <div className="col-md-6">
      <div className="spacer20px" />
      <div className="spacer20px" />
      <div className="spacer20px" />
      <button className="btn btn-base">
      <span className="btn-text" onClick={this.extendTheLockTime}>EXTEND LOCK TIME</span>
      </button>
      </div>
      <div className="col-md-3"></div>
      </div>
      <div className="spacer20px" />
      </div>

      <div id="visitCertificate">
      <div className="row">
      <div className="col-md-3"></div>
      <div className="col-md-6">
      <div className="spacer20px" />
      <div className="spacer20px" />
      <p className="smallFont">Success!</p>
      <div className="spacer20px" />
      <button className="btn btn-base">
      <span className="btn-text" onClick={this.visitCertificate}>VISIT LOCK CERTIFICATE</span>
      </button>
      </div>
      <div className="col-md-3"></div>
      </div>
      <div className="spacer20px" />
      </div>

      <div className="spacer20px" />
      <div className="spacer20px" />
      <div className="spacer20px" />
      <div className="spacer20px" />

      <div className="spacer20px" />
      <div className="row fixed-bottom whiteBg">
      <div className="spacer5px" />
      <div className="col-md-12">
      <p className="smallFont">Created in 2023 - not affiliated with <a className="lblue" href="https://base.org" target="_blank">base.org</a> email: <a href="mailto:contact@basedlock.com">contact@basedlock.com</a> <br />THE SOFTWARE IS PROVIDED “AS IS”, WITHOUT WARRANTY OF ANY KIND.</p>
      </div>
      </div>

      </div>

      </div>
      </Container>
      </div>
      </div>
    )
  }
}
